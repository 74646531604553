<template>
  <div id="basic_info">
    <div  class="repay_pwd pay_pwd_form">
      <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="110px" class="demo-ruleForm">
      <el-form-item label="手机号："  prop="ph_num">
        <div class="personal_info">
          {{infoForm.ph_num}}
        </div>
      </el-form-item>
      <el-form-item label="短信验证码："  prop="v_code" class="v_code">
        <el-input placeholder="请输入短信验证码" autocomplete="new-password" v-model="infoForm.v_code" class="v_code_input"></el-input>
        <div v-if="smsCode" class="v_code_btn" @click="sentVCode">获取</div>
        <div v-else class="v_code_btn" id="smsFalse" >{{ smsTime }}s</div>
      </el-form-item>
      <el-form-item label="支付密码："  prop="pay_pwd">
        <el-input placeholder="请输入支付密码" autocomplete="new-password" v-model="infoForm.pay_pwd" show-password ></el-input>
      </el-form-item>
      <el-form-item label="确认密码："  prop="repay_pwd">
        <el-input placeholder="请确认支付密码" autocomplete="new-password" v-model="infoForm.repay_pwd" show-password></el-input>
      </el-form-item>
    </el-form>
      <div class="submit_btn" @click="submitForm('infoForm')">确认提交</div>
    </div>
  </div>
</template>
<script>
import {apiSmsSend,apiUserPaypwd,apiuserInfo} from "@/request/API";


export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    var validatePayPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入支付密码'))
      } else if (value !== this.infoForm.pay_pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      //头像
      imageUrl: '',
      //上传按钮文本
      elUploadBtn:'点击上传',
      //是否有支付密码
      pwdState:false,
      //支付密码 展示用
      showPaypwd:this.$store.state.userData.paypwd,
      //表单
      infoForm:{
        ph_num:this.$store.state.userData.telphone,
        v_code:'',
        pay_pwd:'',
        repay_pwd:''
      },
      //表单验证
      rules: {
        v_code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        pay_pwd: [
          { required: true, message: '请输入支付密码', trigger: 'blur' },
          { pattern: /^\d{6}$/, message: '密码由6位数字组成' }
        ],
        repay_pwd: [
          { required: true, validator: validatePayPwd, trigger: 'blur' }
        ]
      },
      //获取验证码
      smsCode:true,
      smsTime:60
    }
  },
  methods: {
    //头像上传
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG  && isLt5M;
    },

    //发送验证码
    sentVCode(){
      apiSmsSend({
        phone:this.$store.state.userData.telphone,
        codeType:8
      }).then(res=>{
        this.$message({
          type:"success",
          center:true,
          message:"发送成功"
        })
        this.smsCode = false
      }).catch(err=>{
        this.$message({
          type:"error",
          center:true,
          message:err.msg
        })
      })
    },
    //重设密码
    changePaypwd(){
      this.pwdState = !this.pwdState
    },
    //表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiUserPaypwd({
            paypwd:this.infoForm.pay_pwd,
            paypwd_confirm:this.infoForm.repay_pwd,
            smsCode:this.infoForm.v_code,
          }).then(res=>{
            this.$store.commit('user_paypwd',this.infoForm)
            this.$message({
              message: "设置成功",
              type: 'success',
              center: true
            });
            this.$refs[formName].resetFields();
            this.pwdState = true
          }).catch(err=>{
            this.$message({
              message: err.msg,
              type: 'error',
              center: true
            });
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //获取 支付密码 信息
    getBasicInfo(){
      apiuserInfo({
        action:'base'
      }).then(res=>{
      })
    },
    //获取验证码冷却
    countdownTime(){
      this.smsTime--
      if(this.smsTime === 0){
        this.smsCode = true
      }
    }
  },
  mounted () {
    //判断user是否有支付密码
    if(this.$store.state.userData.paypwd !== ''){
      this.pwdState = true
    }else {
      this.pwdState = false
    }
  },
  watch: {
    smsCode(val, oldVal){//普通的watch监听
      if(!val){
        this.smsTime = 60
        this.countdown = setInterval(this.countdownTime, 1000);
      }else{
        clearInterval(this.countdown)
      }
    },
    imageUrl(){
      if (this.imageUrl !== '') {
        this.elUploadBtn = '重新上传'
      }
    }
  },
  computed: {

  },
  created() {
    if (localStorage.getItem('loginStatus') == "true") {
      this.infoForm.ph_num = JSON.parse(localStorage.getItem('userData')).telphone
    }
  }
}
</script>


<style lang='less' scoped>
#basic_info{
  padding: 60px 0 0 256px;
  display: flex;
  flex-direction: column;
  //align-items: center;

  .pay_pwd_form{
    /deep/.el-input{
      width: 250px;
    }
    /deep/.el-input__inner{
      width: 250px;
      height: 40px;
      background: #F9F9F9;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }
    /deep/.is-error .el-input__inner{
      border-color: #F56C6C;
    }
    .v_code_input,.v_code_input /deep/.el-input__inner{
      width: 180px;
    }
    .v_code_btn{
      margin: 0 0 0 10px !important;
      width: 60px;
      height: 36px;
      line-height: 36px;
      background: #434ED6;
      border-radius: 10px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #FFFFFF;
      cursor: pointer;
      text-align: center;
      display: inline-block;
    }
    #smsFalse{
      background: #f2f2f2;
      cursor: auto;
    }
    /deep/.el-form-item__label{
      text-align: left;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      padding: 0;
    }
    /deep/.el-form-item .el-form-item__label{
      padding: 0 0 0 12px;
    }
    /deep/.is-required .el-form-item__label{
      padding:0;
    }
    .personal_info{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
      padding: 0 0 0 20px;
    }
    .submit_btn{
      margin: 29px 0 0 82px;
      width: 200px;
      height: 40px;
      line-height: 40px;
      background: #434ED6;
      border-radius: 28px;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
    }
  }

}
</style>
